@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
.html ,body {
  font-family: "Montserrat", system-ui, sans-serif !important;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

a {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
}

a:active,
a:focus,
a:hover {
  outline: none;
  color: teal !important;
  text-decoration: none;
}

.bigScreenDiv {
  display: none;
}

.content {
  height: 100%;
  min-height: 100vh;
  background-color: #f3f4f6;
}

.layout {
  height: 100%;
  min-height: 100vh;
  padding-bottom: 50px
}
  
@media only screen and (min-width: 769px) {
  .layout {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
  }

  .content {
    max-width: 500px;
    width: 100%;
  }

    .bigScreenDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("../src/assets/restuarant.jpeg");
      background-size: cover;
      border-radius: 10px;
      opacity: 5px;
      flex-grow: 1;
      height: 100%;
      margin: 10px;
    }
  
    .bigScreenDiv img {
      max-width: 100%;
      height: auto;
    }
}